import { ethers } from 'ethers';
import moment from 'moment';
import 'moment/locale/zh-tw';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import configs from './common/configs';
import { GlobalContext } from './context';
import { lang } from './locale/';
import Index from './pages';
import { detectWallet } from './utils';
import connect from './utils/connect';
import useCatchError from './utils/useCatchError';

function App(props) {
  const {
    state: { global, user },
  } = props;
  const locale = lang[global.locale];
  const network = configs.network[configs.defaultNetwork];
  const [provider, setProvider] = useState();
  const catchError = useCatchError();
  moment.locale(global.locale === 'zh' ? 'zh-tw' : 'en');

  const accountsChanged = accounts => {
    props.actions.login({ account: accounts[0], wallet: user.wallet });
  };

  useEffect(() => {
    const wallet = detectWallet(user.wallet);
    if (wallet) {
      const provider = new ethers.providers.Web3Provider(wallet, 'any');
      provider.on('network', ({ chainId }) => {
        if (+chainId !== network.chainId) {
          props.actions.logout();
          return;
        }
        provider
          .send('eth_requestAccounts')
          .then(accounts => {
            props.actions.login({ account: accounts[0], wallet: user.wallet });
          })
          .catch(catchError);
      });

      wallet.on('accountsChanged', accountsChanged);
      setProvider(provider);
    } else {
      setProvider(null);
    }

    return () => {
      provider &&
        provider.removeAllListeners &&
        provider.removeAllListeners('network');
      wallet &&
        wallet.removeListener &&
        wallet.removeListener('accountsChanged', accountsChanged);
    };
  }, [user.wallet]);

  const contextValue = {
    locale,
    network,
  };

  return (
    <BrowserRouter>
      <GlobalContext.Provider value={{ ...contextValue, provider }}>
        <Routes>
          <Route path="/" element={<Index />}>
            <Route path="/round" element={<Index />}>
              <Route path="/round/:id" element={<Index />} />
            </Route>
          </Route>
        </Routes>
      </GlobalContext.Provider>
    </BrowserRouter>
  );
}

export default connect(App);
