import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { getLocaleLang } from '../utils';

const initialState = {
  locale: getLocaleLang(),
  inviter: null,
};

export default handleActions(
  {
    SET_LANG(state, action) {
      const { locale } = action.payload;
      return update(state, { locale: { $set: locale } });
    },

    SET_INVITER(state, action) {
      const { inviter } = action.payload;
      return update(state, { inviter: { $set: inviter } });
    },
  },

  initialState,
);
