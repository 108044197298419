import { Space } from '@arco-design/web-react';
import React from 'react';
import useLocale from '../../utils/useLocale';
import styles from './index.module.scss';

export default function Footer() {
  const locale = useLocale();
  return (
    <div className={styles.footer}>
      <div>
        <div>
          <img
            src={require('../../assets/logo.png')}
            alt="logo"
            className={styles.logo}
          />
          <div>
            <Space size={20}>
              <span>{locale['fair']}</span>
              <span>{locale['trusted']}</span>
              <span>{locale['decentralized']}</span>
            </Space>
          </div>
        </div>
        <div>
          BEATBIG.XYZ
          <a
            href="https://twitter.com/@beatbiggame"
            target="_blank"
            rel="noreferrer">
            <img src={require('../../assets/twitter.png')} alt="Twitter" />
          </a>
          <a href="https://t.me/BeatBigGame" target="_blank" rel="noreferrer">
            <img src={require('../../assets/telegram.png')} alt="Telegram" />
          </a>
        </div>
      </div>
    </div>
  );
}
