const ZhTW = {
  slogan: '公平  可信  去中心',
  'buy beat': '購買 BEAT',
  'connect wallet': '與錢包連線',
  'total rewards': '已發放獎勵',
  'total airdrop': '已空投',
  'total burn': '已銷毀',
  'your latest rounds': '你最近的輪次',
  withdraw: '提取',
  'token circulation': '代幣流通',
  'total supply': '總量',
  'swap liquidity': 'PancakeSwap 流動池',
  'smart contract address': '智能合约',
  'game core': '本遊戲',
  'random generator': '隨機數發生器',
  'beat token': 'BEAT 代幣',
  'all seats': '所有席位',
  seat: '席位',
  seats: '席位',
  address: '地址',
  date: '日期',
  round: '輪次',
  'how to play': '怎麼玩？',
  'prev round': '上一輪',
  'next round': '下一輪',
  slogan2: '一位參與者最多可獲得 100 倍獎勵回報。',
  slogan3: '所有參與者都可獲得有價值的獎勵回報。',
  'big reward': '大獎',
  airdrop: '空投',
  'seat airdrop': '每席空投',
  burn: '銷毀',
  'seat price': '席位單價',
  'airdrop fee': '用於空投',
  'burn fee': '用於銷毀',
  'treasury fee': '平台費用',
  buy: '購買',
  'max seats': '最大席位',
  approve: '批准',
  'your seats': '你的席位',
  'round result': '本輪結果',
  'lucky seat': '幸運席位',
  'total seats': '總席位數',
  'vrf random': 'VRF 隨機數',
  calculation: '計算方法',
  logout: '中斷連線',
  'metamask wallet': 'Metamask',
  'binance wallet': 'Binance Chain',
  empty: '暂无数据',
  'waiting for the draw': '等待抽奖',
  'withdraw confirming': '确认中',
  'withdraw complete': '已提取',
  'confirm buy': '確認購買',
  loading: '加载中',
  more: '更多',
  'batch withdraw': '批量提取',
  'no batch withdraw': '沒有待處理的提款取',
  'batch withdraw tip': '最多自動合併 10 個輪次的獎勵一次性提取，節省 GAS 費。',
  'your reward': '你的獎勵',

  fair: '公平',
  trusted: '可信',
  decentralized: '去中心',

  lang: '繁體中文',
  message: {
    'metamask not installed': 'Metamask 沒有安裝',
    'balance wallet not install': '幣安錢包沒有安裝',
    'round not exists': '本轮次不存在',
    'seats buy complete': '恭喜，#{seats} 席位購買成功',
    'connect wallet first': '請先與錢包連線',
    'withdraw complete': '提取成功',
    'batch withdraw complete': '#{rounds} 個輪次的批量提取已成功',
  },
  help: [
    'BEATBIG 是運行在 BSC（Binance Smart Chain）網絡上的公平、可信、去中心的區塊鏈遊戲。',
    '遊戲代幣 BEAT，總量十億枚，永不增發，十億枚代幣一次性全部進入去中心交易所 PancakeSwap。',
    [
      '每輪遊戲設置一個幸運大獎，玩家可購買 1 個或多個席位參與。席位數量達到開獎要求即自動開獎抽出一個幸運席位，幸運席位可獲得大獎至少 70% 的份額；',
      '幸運大獎 20% 的份額將自動從去中心交易 PancakeSwap 回購 BEAT，回購的 BEAT 50% 平均空投給本輪沒獲得幸運大獎的席位，50% 永久銷毀；',
      '幸運大獎最多10% 的份額將自動打入平台錢包，用於生態建設；',
      '遊戲抽獎採用 <a href="https://docs.chain.link/docs/chainlink-vrf/" target="_blank">Chainlink VRF</a> 實現真正、安全、可被驗證的隨機性。',
    ],
    '每輪遊戲獎勵都可隨時從智能合約中提取，建議多輪一併提取，節省 GAS 費。',
  ],
};

export default ZhTW;
