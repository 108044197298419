import { connect as connector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../redux/actions';

const mapStateToProps = state => {
  return {
    state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

const connect = cls => {
  return connector(mapStateToProps, mapDispatchToProps)(cls);
};

export default connect;
