import { map } from 'lodash';
import React from 'react';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import styles from './index.module.scss';

export default function Contracts() {
  const locale = useLocale();
  const { browser, contracts } = useNetwork();

  return (
    <div className={styles.contracts}>
      <div className={styles.title}>{locale['smart contract address']}</div>
      <div className={styles.list}>
        {map(contracts, ({ key, address }) => {
          return (
            <div className={styles.item} key={key}>
              <div>{locale[key]}</div>
              <div>
                <a
                  rel="noreferrer"
                  href={`${browser}/address/${address}`}
                  target="_blank">
                  {address}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
