const EnUS = {
  slogan: 'Fair  Trusted  Decentralized',
  'buy beat': 'BUY BEAT',
  'connect wallet': 'Connect Wallet',
  'total rewards': 'Released Rewards',
  'total airdrop': 'Released Airdrops',
  'total burn': 'Total Burned',
  'your latest rounds': 'Your Latest Rounds',
  withdraw: 'Withdraw',
  'token circulation': 'Token Circulation',
  'total supply': 'Total Supply',
  'swap liquidity': 'PancakeSwap Liquidity',
  'smart contract address': 'Smart Contract Address',
  'game core': 'Game',
  'random generator': 'Random Generator',
  'beat token': 'BEAT Token',
  'all seats': 'All Seats',
  seat: 'Seat',
  seats: 'Seats',
  address: 'Address',
  date: 'Date',
  round: 'Round',
  'how to play': 'How to play?',
  'prev round': 'Prev Round',
  'next round': 'Next Round',
  slogan2: 'One player can get up to 10,000% return.',
  slogan3: 'Everyone can get valuable rewards.',
  'big reward': 'Big Reward',
  airdrop: 'Airdrops',
  'seat airdrop': 'Airdrops Per Seat',
  burn: 'Burned',
  'seat price': 'Seat Price',
  'airdrop fee': 'For Airdrops',
  'burn fee': 'For Burn',
  'treasury fee': 'Treasury Fee',
  buy: 'BUY',
  'max seats': 'MAX Seats',
  approve: 'Approve',
  'your seats': 'Your Seats',
  'round result': 'Round Result',
  'lucky seat': 'Lucky Seat',
  'total seats': 'Total Seats',
  'vrf random': 'VRF Random',
  calculation: 'Calculation',
  logout: 'Disconnect',
  'metamask wallet': 'Metamask',
  'binance wallet': 'Binance Chain',
  empty: 'No Data',
  'waiting for the draw': 'Waiting for the draw',
  'withdraw confirming': 'Confirming',
  'withdraw complete': 'Complete',
  'confirm buy': 'CONFIRM BUY',
  loading: 'Loading',
  'batch withdraw': 'Batch Withdraw',
  'no batch withdraw': 'No pending withdrawals',
  'batch withdraw tip':
    'Up to 10 rounds of rewards can be automatically combined with one-time withdrawal, saving GAS fees.',
  'your reward': 'Your Reward',

  fair: 'Fair',
  trusted: 'Trusted',
  decentralized: 'Decentralized',

  lang: 'English',
  message: {
    'metamask not installed': 'Metamask not install',
    'balance wallet not install': 'Balance wallet not install',
    'round not exists': 'This round not exists',
    'seats buy complete': '#{seats} seats have been successfully purchased',
    'connect wallet first': 'Connect your wallet first',
    'withdraw complete': 'Withdraw successfully',
    'batch withdraw complete':
      'Batch withdrawal of #{rounds} rounds has been successful',
  },
  help: [
    'BEATBIG is a Fair, Trusted and Decentralized blockchain game running on the BSC (Binance Smart Chain) network.',
    'The total supply of game tokens BEAT is 1 billion, which will never mint. All 1 billion tokens will enter the decentralized exchange PancakeSwap at once.',
    [
      'Each round of the game sets a big reward, players can buy 1 or more seats to participate. When the number of seats meets the requirements of the round, a lucky seat will be drawn automatically, and the lucky seat will get at least 70% of the big reward.',
      '20% of the big reward will be automatically used to swap BEAT from PancakeSwap. 50% of the buyback BEAT will be airdropped to the seats that have not won the big reward in this round, and the other 50% will be permanently burn.',
      'Up to 10% of the big reward will be automatically transferred to the platform wallet for ecological construction.',
      'The game uses <a href="https://docs.chain.link/docs/chainlink-vrf/" target="_blank">Chainlink VRF</a> to achieve True, Secure and Verifiable randomness.',
    ],
    'Each round of game rewards can be withdrawn from the smart contract at any time. It is recommended to withdraw multiple rounds at the same time to save GAS fees.',
  ],
};

export default EnUS;
