import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

const initialState = {
  loading: false,
  reload: 0,
};

export default handleActions(
  {
    SET_LOADING(state, action) {
      const { loading } = action.payload;
      return update(state, { loading: { $set: loading } });
    },

    RELOAD(state) {
      const { reload } = state;
      return update(state, { reload: { $set: reload + 1 } });
    },

    LOGOUT(state) {
      const { reload } = state;
      return update(state, { reload: { $set: reload + 1 } });
    },
  },

  initialState,
);
