import { Divider, Modal, Space, Typography } from '@arco-design/web-react';
import { IconClose } from '@arco-design/web-react/icon';
import { map } from 'lodash';
import React, { useState } from 'react';
import { formatNumber } from '../../utils/';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import { Button } from '../Vendors';
import styles from './index.module.scss';

export default function Round(props) {
  const locale = useLocale();
  const network = useNetwork();
  const { rootProps, onPrev, onNext } = props;
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const {
    lottery: { latestId, round, fees },
  } = rootProps.state;

  return (
    <div className={styles.round} id="particles-js">
      <div className={styles.top}>
        <div>
          <Button type="text" onClick={onPrev} disabled={round.id <= 1}>
            <img
              src={require('../../assets/prev.png')}
              alt={locale['pre round']}
            />
          </Button>
          <div>
            <div>
              {locale['round']} #{round.id}
            </div>
            <Button
              type="text"
              size="mini"
              onClick={() => setHelpModalVisible(true)}>
              {locale['how to play']}
            </Button>
          </div>
          <Button type="text" onClick={onNext} disabled={round.id >= latestId}>
            <img
              src={require('../../assets/next.png')}
              alt={locale['next round']}
            />
          </Button>
        </div>
      </div>
      <Space direction="vertical" className={styles.slogan} align="center">
        <div>{locale['slogan2']}</div>
        <div>{locale['slogan3']}</div>
      </Space>
      <Space align="center" size={50} className={styles.info}>
        <Space direction="vertical" align="center" size={5}>
          <div>{locale['big reward']}</div>
          <div>
            <span>
              {round.rewardToken &&
                network.tokenMapping[round.rewardToken.toLowerCase()]}
            </span>
            <span>x{formatNumber(round.rewardAmount, 2)}</span>
          </div>
        </Space>
        <Space direction="vertical" align="center" size={5}>
          <div>{locale['seat price']}</div>
          <div>
            <span>
              {round.priceToken &&
                network.tokenMapping[round.priceToken.toLowerCase()]}
            </span>
            <span>x{formatNumber(round.seatPrice, 2)}</span>
          </div>
        </Space>
      </Space>
      <div className={styles.fee}>
        <div>
          <div>{locale['airdrop fee']}</div>
          <div>{fees.airdrop}%</div>
        </div>
        <Divider type="vertical" />
        <div>
          <div>{locale['burn fee']}</div>
          <div>{fees.burn}%</div>
        </div>
        <Divider type="vertical" />
        <div>
          <div>{locale['treasury fee']}</div>
          <div>{fees.treasury}%</div>
        </div>
      </div>
      <Modal
        title={locale['how to play']}
        visible={helpModalVisible}
        simple
        footer={null}
        className={styles.helpModal}
        onOk={() => setHelpModalVisible(false)}
        onCancel={() => setHelpModalVisible(false)}
        autoFocus={false}
        focusLock>
        <div className={styles.wrapper}>
          <Button
            className={styles.closeButton}
            onClick={() => setHelpModalVisible(false)}>
            <IconClose />
          </Button>
          {map(locale['help'], (item, i) => {
            return (
              <Typography.Paragraph key={i} className={styles.item}>
                {typeof item === 'object' ? (
                  <ul>
                    {map(item, (subitem, i) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<li key={i}>${subitem}</li>`,
                          }}></div>
                      );
                    })}
                  </ul>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}></div>
                )}
              </Typography.Paragraph>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
