import { utils } from 'ethers';
import { padEnd, round, split } from 'lodash';
import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    s: 'few seconds',
  },
});

export const reduceString = (
  string,
  head = 0,
  tail = 0,
  replacement = '****',
) => {
  return (
    string.substring(0, head) +
    replacement +
    string.substring(string.length - tail)
  );
};

export const reduceAddress = (address, head = 15, tail = 15, replacement) => {
  return reduceString(address, head, tail, replacement);
};

export const getLocaleLang = () => {
  let lang = 'en';
  if (window) {
    let locale = window.Navigator.language || navigator.browserLanguage;
    if (locale) {
      locale = locale.toLowerCase();

      if (locale.indexOf('zh') !== -1) {
        lang = 'zh';
      } else {
        lang = 'en';
      }
    }
  }

  return lang;
};

export const toHex = number => {
  return '0x' + number.toString(16);
};

export const detectWallet = type => {
  if (type === 'metamask') {
    if (window.ethereum && window.ethereum.isMetaMask) {
      return window.ethereum;
    }
  } else if (type === 'bsc') {
    return window.BinanceChain;
  }
  return null;
};

export const formatTimestamp = timestamp => {
  return moment(timestamp * 1000).fromNow();
};

export const formatNumber = (
  number,
  precision = 2,
  options = { commify: true, ether: true, pad: true },
) => {
  const { commify = true, ether = true, pad = true } = options;
  let formatted = 0;
  if (typeof number === 'number' || typeof number === 'string') {
    formatted = +number;
    if (isNaN(formatted)) {
      return 0;
    }
  } else if (typeof number === 'object') {
    if (number._isBigNumber) {
      if (ether) {
        formatted = +utils.formatEther(number);
      } else {
        formatted = number.toNumber();
      }
    }
  }

  formatted = round(formatted, precision);
  if (commify) {
    formatted = utils.commify(formatted);
  }

  if (pad && precision) {
    const [left, right = '0'] = split(formatted, '.');
    formatted = left + '.' + padEnd(right, precision, '0');
  }

  return formatted;
};
