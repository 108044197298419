import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

const initialState = {
  latestId: 0,
  round: {},
  seats: [],
  fees: { airdrop: 0, burn: 0, treasury: 0 },
};

export default handleActions(
  {
    UPDATE_LOTTERY_LATEST_ID(state, action) {
      const { latestId } = action.payload;
      return update(state, { latestId: { $set: latestId } });
    },

    UPDATE_LOTTERY_ROUND(state, action) {
      return update(state, { round: { $set: action.payload } });
    },

    UPDATE_LOTTERY_FEES(state, action) {
      const [airdrop, burn, treasury] = action.payload;
      return update(state, {
        fees: {
          $set: {
            airdrop: airdrop.div(100).toNumber(),
            burn: burn.div(100).toNumber(),
            treasury: treasury.div(100).toNumber(),
          },
        },
      });
    },

    UPDATE_LOTTERY_SEATS(state, action) {
      return update(state, { seats: { $set: action.payload } });
    },

    LOGOUT() {
      return initialState;
    },
  },

  initialState,
);
