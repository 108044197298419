const LotteryStatus = {
  Pending: 0,
  Open: 1,
  Full: 2,
  Close: 3,
  Claimable: 4,
};

const WithdrawStatus = {
  Idle: 0,
  Request: 1,
  Confirming: 2,
  Complete: 3,
};

export { LotteryStatus, WithdrawStatus };
