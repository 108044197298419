import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

const initialState = {
  account: null,
  wallet: null, // metamask or bsc
};

export default handleActions(
  {
    LOGIN(state, action) {
      return update(state, { $merge: { ...action.payload } });
    },

    LOGOUT() {
      return initialState;
    },
  },

  initialState,
);
