import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    UPDATE_LOTTERY_WITHDRAW(state, action) {
      const { account, data } = action.payload;
      return update(state, {
        [account]: statuses => {
          return update(statuses || {}, { $merge: data });
        },
      });
    },
  },

  initialState,
);
