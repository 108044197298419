export const SET_LANG = 'SET_LANG';
export const SET_INVITER = 'SET_INVITER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CONNECT_WALLET = 'CONNECT_WALLET';
export const UPDATE_LOTTERY_LATEST_ID = 'UPDATE_LOTTERY_LATEST_ID';
export const UPDATE_LOTTERY_ROUND = 'UPDATE_LOTTERY_ROUND';
export const UPDATE_LOTTERY_SEATS = 'UPDATE_LOTTERY_SEATS';
export const UPDATE_LOTTERY_FEES = 'UPDATE_LOTTERY_FEES';
export const UPDATE_LOTTERY_WITHDRAW = 'UPDATE_LOTTERY_WITHDRAW';
export const SET_LOADING = 'SET_LOADING';
export const RELOAD = 'RELOAD';
