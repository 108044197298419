import { Affix, Layout, Message, Modal, Spin } from '@arco-design/web-react';
import { ethers } from 'ethers';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Contracts from '../components/Contracts';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Draw, Payment, Round } from '../components/Main/';
import Rewards from '../components/Rewards';
import Seats from '../components/Seats';
import Token from '../components/Token';
import connect from '../utils/connect';
import useCatchError from '../utils/useCatchError';
import useContract from '../utils/useContract';
import useLocale from '../utils/useLocale';

function Index(props) {
  const { LotteryContract } = useContract();
  const locale = useLocale();
  const catchError = useCatchError();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    lottery: { latestId },
    ui: { reload },
  } = props.state;

  useEffect(() => {
    const inviter = new URLSearchParams(location.search).get('from');
    if (inviter && ethers.utils.isAddress(inviter)) {
      props.actions.setInviter({ inviter });
    }
  }, []);

  useEffect(() => {
    let timer = setInterval(() => {
      LotteryContract.getCurrentLotteryId()
        .then(result => {
          const _latestId = result.toNumber();
          if (_latestId > latestId) {
            props.actions.updateLotteryLatestId({ latestId: _latestId });
          }
        })
        .catch(() => {});
    }, 10000);

    return () => {
      timer && clearInterval(timer);
      timer = null;
    };
  }, []);

  useEffect(() => {
    props.actions.setLoading({ loading: true });

    LotteryContract.getCurrentLotteryId()
      .then(result => {
        const latestId = result.toNumber();
        props.actions.updateLotteryLatestId({ latestId });

        const id = +params.id;
        if (isNaN(id) || id < 1 || id > latestId) {
          if (!isNaN(id)) {
            Message.error(locale['message']['round not exists']);
          }
          navigate({
            pathname: `/round/${id < 1 ? 1 : latestId}`,
            search: location.search,
          });
          return;
        }

        Promise.all([
          LotteryContract.getLottery(id),
          LotteryContract.getLotteryFees(id),
          LotteryContract.getLotterySeats(id),
        ])
          .then(results => {
            props.actions.updateLotteryRound({ id, ...results[0] });
            props.actions.updateLotteryFees([...results[1]]);
            props.actions.updateLotterySeats([...results[2]]);
          })
          .catch(catchError)
          .finally(() => {
            props.actions.setLoading({ loading: false });
          });
      })
      .catch(e => {
        props.actions.setLoading({ loading: false });
        catchError(e);
      });
  }, [params.id, reload]);

  const onPrev = () => {
    if (params.id > 1) {
      navigate({
        pathname: `/round/${params.id - 1}`,
        search: location.search,
      });
    }
  };

  const onNext = () => {
    const {
      lottery: { latestId },
    } = props.state;
    if (params.id < latestId) {
      navigate({
        pathname: `/round/${+params.id + 1}`,
        search: location.search,
      });
    }
  };

  return (
    <Layout className="container">
      <Layout.Header>
        <Affix>
          <Header rootProps={props} />
        </Affix>
      </Layout.Header>
      <Layout className="main">
        <Layout.Sider className="sider">
          <Rewards rootProps={props} />
          <Token />
          <Contracts />
        </Layout.Sider>
        <Layout.Content className="content">
          <div>
            <div>
              <Round rootProps={props} onPrev={onPrev} onNext={onNext} />
              <Payment rootProps={props} />
              <Draw rootProps={props} />
            </div>
            <div>
              <Seats rootProps={props} />
            </div>
          </div>
        </Layout.Content>
      </Layout>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
      <Modal
        visible={props.state.ui.loading}
        simple
        footer={null}
        className="loadingModal"
        autoFocus={false}
        focusLock>
        <div className="wrapper">
          <Spin size={10} dot></Spin>
        </div>
      </Modal>
    </Layout>
  );
}

export default connect(Index);
