import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../utils/';
import useCatchError from '../../utils/useCatchError';
import useContract from '../../utils/useContract';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import styles from './index.module.scss';

export default function Token() {
  const locale = useLocale();
  const network = useNetwork();
  const catchError = useCatchError();

  const { BEATContract } = useContract();
  const [totalSupply, setTotalSupply] = useState(0);
  const [burn, setBurn] = useState(0);
  const [liquidity, setLiquidity] = useState(0);

  useEffect(() => {
    BEATContract.totalSupply()
      .then(result => {
        const totalSupply = formatNumber(result, 0, {
          commify: false,
        });
        setTotalSupply(ethers.utils.commify(totalSupply));
        setBurn(
          formatNumber(network.contracts.token.supply - totalSupply, 0, {
            ether: false,
          }),
        );
      })
      .catch(catchError);
    BEATContract.balanceOf(network.token.LP)
      .then(result => {
        setLiquidity(formatNumber(result, 0));
      })
      .catch(catchError);
  }, []);

  return (
    <div className={styles.token}>
      <div className={styles.title}>{locale['token circulation']}</div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div>{locale['total supply']}</div>
          <div>{totalSupply}</div>
        </div>
        <div className={styles.item}>
          <div>{locale['total burn']}</div>
          <div>{burn}</div>
        </div>
        <div className={styles.item}>
          <div>{locale['swap liquidity']}</div>
          <div>{liquidity}</div>
        </div>
      </div>
    </div>
  );
}
