import EnUS from './en-US';
import ZhTW from './zh-TW';

const support = {
  en: 'English',
  zh: '繁體中文',
};

const lang = {
  en: EnUS,
  zh: ZhTW,
};

export { lang, support };
