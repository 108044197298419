import { combineReducers } from 'redux';
import global from './global';
import lottery from './lottery';
import ui from './ui';
import user from './user';
import withdraw from './withdraw';

const rootReducer = combineReducers({ global, user, lottery, ui, withdraw });

export default rootReducer;
