import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import sagas from '../sagas';

const __DEV__ = process.env.NODE_ENV === 'development';
const configureStore = initState => {
  const middleware = [];

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  if (__DEV__) {
    middleware.push(createLogger());
  }

  const persistConfig = {
    key: 'BEATBIG-ROOT',
    storage,
    whitelist: ['global', 'user'],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    initState,
    compose(applyMiddleware(...middleware)),
  );
  sagas.map(saga => sagaMiddleware.run(saga));

  const persistedStore = persistStore(store);
  return { store, persistedStore };
};

export default configureStore;
