import { ethers } from 'ethers';
import { BeatBigLottery, ERC20, PancakeSwapRouter } from '../common/abis';
import useNetwork from './useNetwork';
import useProvider from './useProvider';

let contracts;
function useContract() {
  const network = useNetwork();
  const provider = useProvider();

  if (!contracts) {
    contracts = {
      LotteryContract: new ethers.Contract(
        network.contracts.game.address,
        BeatBigLottery,
        provider,
      ),
      SwapRouteContract: new ethers.Contract(
        network.swap.router,
        PancakeSwapRouter,
        provider,
      ),
      BEATContract: new ethers.Contract(network.token.BEAT, ERC20, provider),
    };
  }

  return contracts;
}

export default useContract;
