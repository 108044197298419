import { Divider, Grid, Pagination } from '@arco-design/web-react';
import { slice } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { LotteryStatus } from '../../common';
import { Empty } from '../../components/Vendors';
import { formatTimestamp, reduceAddress } from '../../utils';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import styles from './index.module.scss';

const PAGE_SIZE = 15;
export default function Seats(props) {
  const locale = useLocale();
  const network = useNetwork();
  const { rootProps } = props;
  const {
    lottery: { seats, round },
    ui: { loading },
  } = rootProps.state;
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  useEffect(() => {
    setPage(1);
  }, [round, seats]);

  useEffect(() => {
    if (seats.length === 0) {
      return;
    }

    const end = seats.length - (page - 1) * PAGE_SIZE;
    const start = Math.max(end - PAGE_SIZE, 0);
    setList(slice(seats, start, end).reverse());
  }, [page, seats]);

  const onPageChange = pageNum => {
    setPage(pageNum);
  };

  return (
    <div className={styles.seats}>
      <div className={styles.title}>{locale['all seats']}</div>
      <Grid.Row className={styles.header}>
        <Grid.Col span={3}>{locale['seat']}</Grid.Col>
        <Grid.Col span={16}>{locale['address']}</Grid.Col>
        <Grid.Col span={5}>{/* {locale['date']} */}</Grid.Col>
      </Grid.Row>
      <div className={styles.list}>
        {seats.length === 0 ? (
          <Empty loading={loading} />
        ) : (
          list.map(({ owner, timestamp }, i) => {
            const seat = seats.length - (page - 1) * PAGE_SIZE - i - 1;
            const lucky =
              round.status === LotteryStatus.Claimable &&
              round.luckyNumber.toNumber() === seat;
            return (
              <Fragment key={i}>
                <Grid.Row
                  className={[styles.item, lucky ? styles.lucky : null].join(
                    ' ',
                  )}
                  align="center">
                  <Grid.Col span={3} className="highlight">
                    #{seat}
                  </Grid.Col>
                  <Grid.Col span={16}>
                    <a
                      href={`${network.browser}/address/${owner}`}
                      rel="noreferrer"
                      target="_blank">
                      {reduceAddress(owner)}
                    </a>
                    <span className={styles.badge}>{locale['lucky seat']}</span>
                  </Grid.Col>
                  <Grid.Col span={5}>
                    {formatTimestamp(timestamp.toNumber())}
                  </Grid.Col>
                </Grid.Row>
                <Divider />
              </Fragment>
            );
          })
        )}
        {seats.length > PAGE_SIZE ? (
          <Pagination
            className={styles.pager}
            total={seats.length}
            pageSize={PAGE_SIZE}
            current={page}
            defaultCurrent={1}
            size="mini"
            onChange={onPageChange}
          />
        ) : null}
      </div>
    </div>
  );
}
