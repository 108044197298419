import {
  Divider,
  Grid,
  Message,
  Space,
  Statistic,
} from '@arco-design/web-react';
import { ethers } from 'ethers';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LotteryStatus, WithdrawStatus } from '../../common';
import { BeatBigLottery } from '../../common/abis';
import { Button, Empty } from '../../components/Vendors';
import { formatNumber } from '../../utils';
import useCatchError from '../../utils/useCatchError';
import useContract from '../../utils/useContract';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import useWeb3Provider from '../../utils/useWeb3Provider';
import styles from './index.module.scss';

export default function Draw(props) {
  const locale = useLocale();
  const { rootProps } = props;
  const {
    lottery: { round, seats: totalSeats, fees },
    user,
    withdraw,
    ui: { loading },
  } = rootProps.state;
  const catchError = useCatchError();
  const web3Provider = useWeb3Provider();
  const { LotteryContract } = useContract();
  const [seats, setSeats] = useState([]);
  const [reward, setReward] = useState({});
  const [innerLoading, setInnerLoading] = useState(false);
  const network = useNetwork();

  const getWithdrawStatus = () => {
    return get(withdraw, `${user.account}.${round.id}`, WithdrawStatus.Idle);
  };

  const updateWithdrawStatus = status => {
    rootProps.actions.updateLotteryWithdraw({
      account: user.account,
      data: { [round.id]: status },
    });
  };

  const onWithdraw = () => {
    updateWithdrawStatus(WithdrawStatus.Request);
    const contract = new ethers.Contract(
      network.contracts.game.address,
      BeatBigLottery,
      web3Provider.getSigner(),
    );
    contract
      .claimSeats(round.id)
      .then(tx => {
        updateWithdrawStatus(WithdrawStatus.Confirming);
        tx.wait()
          .then(() => {
            updateWithdrawStatus(WithdrawStatus.Complete);
            Message.success(locale['message']['withdraw complete']);
          })
          .catch(e => {
            updateWithdrawStatus(WithdrawStatus.Idle);
            catchError(e);
          });
      })
      .catch(e => {
        updateWithdrawStatus(WithdrawStatus.Idle);
        catchError(e);
      });
  };

  useEffect(() => {
    setSeats([]);
  }, [round.id, user]);

  useEffect(() => {
    if (isEmpty(round)) {
      return;
    }

    if (user.account) {
      setInnerLoading(true);
      LotteryContract.getUserSeats(round.id, user.account)
        .then(setSeats)
        .catch(catchError)
        .finally(() => setInnerLoading(false));

      LotteryContract.getUserReward(round.id, user.account)
        .then(result => {
          setReward(result);
        })
        .catch(() => {});
    }

    if (
      round.status === LotteryStatus.Full ||
      round.status === LotteryStatus.Close
    ) {
      let timer = setInterval(() => {
        LotteryContract.getLottery(round.id)
          .then(result => {
            if (result.status === LotteryStatus.Claimable) {
              clearInterval(timer);
              timer = null;

              rootProps.actions.updateLotteryRound({
                id: round.id,
                ...result,
              });
            }
          })
          .catch(() => {});
      }, 5000);
      return () => {
        timer && clearInterval(timer);
        timer = null;
      };
    }
  }, [user, round.id, round.status, totalSeats]);

  return (
    <div className={styles.draw}>
      <div className={styles.title}>{locale['your seats']}</div>
      <div className={styles.seats}>
        {seats.length === 0 ? (
          <Empty loading={loading || innerLoading} />
        ) : (
          <Space size={10} wrap>
            {seats.map((seat, i) => {
              return (
                <div
                  className={[
                    styles.seat,
                    round.status === LotteryStatus.Claimable &&
                    seat.eq(round.luckyNumber)
                      ? styles.lucky
                      : null,
                  ].join(' ')}
                  key={i}>
                  #{seat.toString()}
                </div>
              );
            })}
          </Space>
        )}
      </div>
      {round.status !== LotteryStatus.Claimable || seats.length === 0 ? null : (
        <>
          <div className={styles.title}>{locale['your reward']}</div>
          <div className={styles.reward}>
            <Space size={20}>
              <div>
                {network.tokenMapping[round.rewardToken.toLowerCase()]}{' '}
                {formatNumber(reward.lucky)}
              </div>
              <div>BEAT {formatNumber(reward.airdrop)}</div>
              {getWithdrawStatus() === WithdrawStatus.Complete ||
              reward.claimed ? (
                <span className={styles.status}>
                  {locale['withdraw complete']}
                </span>
              ) : (
                <Button
                  type="primary"
                  size="mini"
                  onClick={onWithdraw}
                  loading={
                    getWithdrawStatus() === WithdrawStatus.Request ||
                    getWithdrawStatus() === WithdrawStatus.Confirming
                  }>
                  {locale['withdraw']}
                </Button>
              )}
            </Space>
          </div>
        </>
      )}
      <div className={styles.title}>{locale['round result']}</div>
      {round.status === LotteryStatus.Claimable ? (
        <div className={styles.result}>
          <Grid.Row gutter={20}>
            <Grid.Col span={4} align="right">
              {locale['lucky seat']}
            </Grid.Col>
            <Grid.Col span={20}>
              <div className={[styles.seat, styles.lucky].join(' ')}>
                #{(round.luckyNumber && round.luckyNumber.toString()) || '-'}
              </div>
            </Grid.Col>
          </Grid.Row>
          <Divider />
          <Grid.Row gutter={20}>
            <Grid.Col span={4} align="right">
              {locale['total seats']}
            </Grid.Col>
            <Grid.Col span={20}>
              {(round.seatCapacity && round.seatCapacity.toString()) || '-'}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row gutter={20}>
            <Grid.Col span={4} align="right">
              {locale['vrf random']}
            </Grid.Col>
            <Grid.Col span={20}>
              <div style={{ maxWidth: 500, wordWrap: 'break-word' }}>
                {(round.luckyNumberSeed && round.luckyNumberSeed.toString()) ||
                  '-'}
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row gutter={20}>
            <Grid.Col span={4} align="right">
              {locale['calculation']}
            </Grid.Col>
            <Grid.Col span={20}>
              {locale['lucky seat']} = {locale['vrf random']} %{' '}
              {locale['total seats']}
            </Grid.Col>
          </Grid.Row>
          <div className={[styles.stat]}>
            <Statistic
              title={locale['big reward']}
              value={formatNumber(
                round.rewardAmount
                  .mul(100 - fees.airdrop - fees.burn - fees.treasury)
                  .div(100),
              )}
              suffix={network.tokenMapping[round.rewardToken.toLowerCase()]}
            />
            <Statistic
              title={locale['airdrop']}
              value={formatNumber(round.amountAirdropInBeat)}
              suffix="BEAT"
            />
            <Statistic
              title={locale['seat airdrop']}
              value={formatNumber(round.amountAirdropInBeatPerSeat)}
              suffix="BEAT"
            />
            <Statistic
              title={locale['burn']}
              value={formatNumber(round.amountBurnInBeat)}
              suffix="BEAT"
            />
          </div>
        </div>
      ) : (
        <Empty
          className={styles.empty}
          loading={loading || round.status > LotteryStatus.Open}
          description={
            locale[
              round.status > LotteryStatus.Open
                ? 'waiting for the draw'
                : loading
                ? 'loading'
                : 'empty'
            ]
          }
        />
      )}
    </div>
  );
}
