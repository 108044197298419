import { createAction } from 'redux-actions';
import * as types from './types';

export const setLang = createAction(types.SET_LANG);
export const setInviter = createAction(types.SET_INVITER);
export const login = createAction(types.LOGIN);
export const logout = createAction(types.LOGOUT);
export const connectWallet = createAction(types.CONNECT_WALLET);
export const updateLotteryLatestId = createAction(
  types.UPDATE_LOTTERY_LATEST_ID,
);
export const updateLotteryRound = createAction(types.UPDATE_LOTTERY_ROUND);
export const updateLotteryFees = createAction(types.UPDATE_LOTTERY_FEES);
export const updateLotterySeats = createAction(types.UPDATE_LOTTERY_SEATS);
export const updateLotteryWithdraw = createAction(
  types.UPDATE_LOTTERY_WITHDRAW,
);
export const setLoading = createAction(types.SET_LOADING);
export const reload = createAction(types.RELOAD);
