const configs = {
  defaultNetwork: 'bscmainnet',
  network: {
    // bsctestnet: {
    //   url: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    //   chainId: 97,
    //   networkId: 'bsc-testnet',
    //   chainName: 'Binance Smart Chain Testnet',
    //   chainToken: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    //   browser: 'https://testnet.bscscan.com',
    //   swap: {
    //     url: 'https://pancake.kiemtienonline360.com/#/swap',
    //     router: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
    //   },
    //   token: {
    //     BEAT: '0x47e1dE5B4e7Db788000Ae753F573e6693d9F0299',
    //     BUSD: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
    //     WETH: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    //     LP: '0xf680B6EEb3B8991044E0A188A0016B0d62d11AbF',
    //     BNB: '0x0000000000000000000000000000000000000000',
    //     DAI: '0x8a9424745056Eb399FD19a0EC26A14316684e274',
    //     USDT: '0x7ef95a0fee0dd31b22626fa2e10ee6a223f8a684',
    //   },
    //   contracts: {
    //     game: {
    //       address: '0xffaC6fF977a063A9d8DF3753c7885d96B8EA69D3',
    //       key: 'game core',
    //     },
    //     random: {
    //       address: '0xC31A690208c58B371451c53bCF555486F5A9e03c',
    //       key: 'random generator',
    //     },
    //     token: {
    //       address: '0x47e1dE5B4e7Db788000Ae753F573e6693d9F0299',
    //       key: 'beat token',
    //       supply: 1000000000,
    //     },
    //   },
    // },
    bscmainnet: {
      url: 'https://bsc-dataseed1.ninicoin.io',
      chainId: 56,
      networkId: 'bsc-mainnet',
      chainName: 'Binance Smart Chain Mainnet',
      chainToken: { name: 'BNB', symbol: 'BNB', decimals: 18 },
      browser: 'https://bscscan.com',
      swap: {
        url: 'https://pancakeswap.finance/swap',
        router: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
      },
      token: {
        BEAT: '0xb22dbe21253c21fb77284108d2108a7e8346c585',
        LP: '0x0f80bf4ba1d80da4eef4bf457678c13cdd62d52e',
        BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        WETH: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        BNB: '0x0000000000000000000000000000000000000000',
        DAI: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        USDT: '0x55d398326f99059ff775485246999027b3197955',
      },
      contracts: {
        game: {
          address: '0x15ef2ba4d3a2c65eb05cd7cfdaf8cedce8400b46',
          key: 'game core',
        },
        random: {
          address: '0xf9d7443309cdd2fdd0d11e6d9399c6e0324f454d',
          key: 'random generator',
        },
        token: {
          address: '0xb22dbe21253c21fb77284108d2108a7e8346c585',
          key: 'beat token',
          supply: 1000000000,
        },
      },
    },
  },
};

export default configs;
