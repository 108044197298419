import { Message } from '@arco-design/web-react';
import { useDispatch } from 'react-redux';
import { SET_LOADING } from '../redux/types';

function useCatchError() {
  const dispatch = useDispatch();
  return e => {
    const { data, message, error } = e;
    dispatch({ type: SET_LOADING, payload: { loading: false } });
    Message.error((data && data.message) || message || error);
  };
}

export default useCatchError;
