import { ethers } from 'ethers';
import useNetwork from './useNetwork';

let provider;
function useProvider() {
  const { url } = useNetwork();
  if (!provider) {
    provider = new ethers.providers.JsonRpcProvider(url);
  }

  return provider;
}

export default useProvider;
