import { Grid, Message, Space, Tooltip } from '@arco-design/web-react';
import { ethers } from 'ethers';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LotteryStatus, WithdrawStatus } from '../../common';
import BeatBigLottery from '../../common/abis/BeatBigLottery';
import { Empty } from '../../components/Vendors';
import { formatNumber } from '../../utils';
import useCatchError from '../../utils/useCatchError';
import useContract from '../../utils/useContract';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import useWeb3Provider from '../../utils/useWeb3Provider';
import { Button } from '../Vendors';
import styles from './index.module.scss';

export default function Rewards(props) {
  const locale = useLocale();
  const { rootProps } = props;
  const {
    user,
    lottery: { round },
    withdraw,
  } = rootProps.state;

  const catchError = useCatchError();
  const network = useNetwork();
  const web3Provider = useWeb3Provider();
  const { LotteryContract } = useContract();

  const [innerLoading, setInnerLoading] = useState(false);
  const [batchWithdrawLoading, setBatchWithdrawLoading] = useState(false);
  const [rounds, setRounds] = useState([]);
  const [list, setList] = useState([]);

  const getList = async lotteryIds => {
    const lotteries = [];
    for (let i = lotteryIds.length - 1; i >= 0; i--) {
      const lotteryId = lotteryIds[i].toNumber();
      const lottery = await LotteryContract.getLottery(lotteryId);
      if (lottery.status === LotteryStatus.Claimable) {
        const reward = await LotteryContract.getUserReward(
          lotteryId,
          user.account,
        );
        lotteries.push({
          lotteryId,
          reward,
          ...lottery,
        });

        if (lotteries.length === 5) {
          break;
        }
      }
    }

    return lotteries;
  };

  const getWithdrawStatus = lotteryId => {
    return get(withdraw, `${user.account}.${lotteryId}`, WithdrawStatus.Idle);
  };

  const updateWithdrawStatus = data => {
    rootProps.actions.updateLotteryWithdraw({ account: user.account, data });
  };

  const onWithdraw = lotteryId => {
    updateWithdrawStatus({
      [lotteryId]: WithdrawStatus.Request,
    });
    const contract = new ethers.Contract(
      network.contracts.game.address,
      BeatBigLottery,
      web3Provider.getSigner(),
    );
    contract
      .claimSeats(lotteryId)
      .then(tx => {
        updateWithdrawStatus({
          [lotteryId]: WithdrawStatus.Confirming,
        });
        tx.wait()
          .then(() => {
            updateWithdrawStatus({
              [lotteryId]: WithdrawStatus.Complete,
            });
            Message.success(locale['message']['withdraw complete']);
          })
          .catch(e => {
            catchError(e);
            updateWithdrawStatus({
              [lotteryId]: WithdrawStatus.Idle,
            });
          });
      })
      .catch(e => {
        catchError(e);
        updateWithdrawStatus({
          [lotteryId]: WithdrawStatus.Idle,
        });
      });
  };

  const onBatchWithdraw = async () => {
    const lotteryIds = [];
    setBatchWithdrawLoading(true);
    for (let id of [...rounds].reverse()) {
      id = id.toNumber();
      if (getWithdrawStatus(id) === WithdrawStatus.Complete) {
        continue;
      }

      const { lucky, airdrop, claimed } = await LotteryContract.getUserReward(
        id,
        user.account,
      );
      if (!claimed && (!lucky.isZero() || !airdrop.isZero())) {
        lotteryIds.push(id);
      }

      if (lotteryIds.length === 10) {
        break;
      }
    }
    if (lotteryIds.length === 0) {
      setBatchWithdrawLoading(false);
      Message.info(locale['no batch withdraw']);
      return;
    }

    const statuses = {};
    for (let lotteryId of lotteryIds) {
      statuses[lotteryId] = WithdrawStatus.Request;
    }
    updateWithdrawStatus(statuses);
    const contract = new ethers.Contract(
      network.contracts.game.address,
      BeatBigLottery,
      web3Provider.getSigner(),
    );
    contract
      .batchClaimSeats(lotteryIds)
      .then(tx => {
        const statuses = {};
        for (let lotteryId of lotteryIds) {
          statuses[lotteryId] = WithdrawStatus.Confirming;
        }
        updateWithdrawStatus(statuses);
        tx.wait()
          .then(() => {
            const statuses = {};
            for (let lotteryId of lotteryIds) {
              statuses[lotteryId] = WithdrawStatus.Complete;
            }
            updateWithdrawStatus(statuses);
            Message.success(
              locale['message']['batch withdraw complete'].replace(
                '#{rounds}',
                lotteryIds.length,
              ),
            );
          })
          .catch(e => {
            catchError(e);
            const statuses = {};
            for (let lotteryId of lotteryIds) {
              statuses[lotteryId] = WithdrawStatus.Idle;
            }
            updateWithdrawStatus(statuses);
          })
          .finally(() => setBatchWithdrawLoading(false));
      })
      .catch(e => {
        const statuses = {};
        for (let lotteryId of lotteryIds) {
          statuses[lotteryId] = WithdrawStatus.Idle;
        }
        updateWithdrawStatus(statuses);
        setBatchWithdrawLoading(false);
        catchError(e);
      });
  };

  useEffect(() => {
    setList([]);
  }, [user]);

  useEffect(() => {
    if (!user.account) {
      return;
    }

    setInnerLoading(true);
    LotteryContract.getUserRounds(user.account)
      .then(result => {
        setRounds(result);
        getList(result)
          .then(setList)
          .catch(catchError)
          .finally(() => setInnerLoading(false));
      })
      .catch(e => {
        setInnerLoading(false);
        catchError(e);
      });
  }, [user, round.id, round.status]);

  return (
    <div className={styles.rewards}>
      <div className={styles.title}>
        <div>{locale['your latest rounds']}</div>
        {list.length === 0 ? null : (
          <Tooltip
            mini
            position="tl"
            trigger="hover"
            content={locale['batch withdraw tip']}>
            <Button
              type="text"
              size="mini"
              onClick={onBatchWithdraw}
              loading={batchWithdrawLoading}>
              {locale['batch withdraw']}
            </Button>
          </Tooltip>
        )}
      </div>
      <div className={styles.list}>
        {list.length === 0 ? (
          <Empty loading={innerLoading} />
        ) : (
          list.map(({ lotteryId, rewardToken, reward }, i) => {
            return (
              <Grid.Row className={styles.item} align="center" key={i}>
                <Grid.Col span={4}>
                  <Link className="highlight" to={`/round/${lotteryId}`}>
                    #{lotteryId}
                  </Link>
                </Grid.Col>
                <Grid.Col span={14}>
                  <Space>
                    <div>
                      {network.tokenMapping[rewardToken.toLowerCase()]}{' '}
                      {formatNumber(reward.lucky, 2)}
                    </div>
                    <div>BEAT {formatNumber(reward.airdrop, 2)}</div>
                  </Space>
                </Grid.Col>
                <Grid.Col span={6} align="right">
                  {getWithdrawStatus(lotteryId) === WithdrawStatus.Complete ||
                  reward.claimed ? (
                    <span className={styles.status}>
                      {locale['withdraw complete']}
                    </span>
                  ) : (
                    <Button
                      size="mini"
                      type="primary"
                      loading={
                        getWithdrawStatus(lotteryId) ===
                          WithdrawStatus.Request ||
                        getWithdrawStatus(lotteryId) ===
                          WithdrawStatus.Confirming ||
                        batchWithdrawLoading
                      }
                      onClick={() => onWithdraw(lotteryId)}>
                      {locale['withdraw']}
                    </Button>
                  )}
                </Grid.Col>
              </Grid.Row>
            );
          })
        )}
      </div>
    </div>
  );
}
