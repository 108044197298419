import { Message, Progress, Space } from '@arco-design/web-react';
import { ethers } from 'ethers';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LotteryStatus } from '../../common';
import { BeatBigLottery, ERC20 } from '../../common/abis';
import { formatNumber } from '../../utils';
import useCatchError from '../../utils/useCatchError';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import useWeb3Provider from '../../utils/useWeb3Provider';
import { Button } from '../Vendors';
import styles from './index.module.scss';

const SEATS = [1, 2, 5, 0];
export default function Payment(props) {
  const locale = useLocale();
  const network = useNetwork();
  const { rootProps } = props;
  const {
    lottery: { round, seats },
    user,
    global: { inviter },
  } = rootProps.state;
  const [buySeats, setBuySeats] = useState(1);
  const [payAmount, setPayAmount] = useState(0);
  const [remainSeats, setRemainSeats] = useState(0);
  const [innerLoading, setInnerLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const web3Provider = useWeb3Provider();
  const catchError = useCatchError();

  const onApprove = () => {
    if (!web3Provider) {
      Message.warning(locale['message']['connect wallet first']);
      return;
    }
    if (round.priceToken === ethers.constants.AddressZero) {
      onBuy();
      return;
    }
    setInnerLoading(true);
    const contract = new ethers.Contract(
      round.priceToken,
      ERC20,
      web3Provider.getSigner(),
    );
    contract
      .approve(network.contracts.game.address, payAmount)
      .then(tx => {
        tx.wait()
          .then(() => {
            setApprove(true);
          })
          .catch(catchError)
          .finally(() => setInnerLoading(false));
      })
      .catch(e => {
        setInnerLoading(false);
        catchError(e);
      });
  };

  const onBuy = () => {
    if (!web3Provider) {
      Message.warning(locale['message']['connect wallet first']);
      return;
    }

    setInnerLoading(true);
    const contract = new ethers.Contract(
      network.contracts.game.address,
      BeatBigLottery,
      web3Provider.getSigner(),
    );
    const seats = buySeats === 0 ? remainSeats : buySeats;
    contract
      .buySeats(round.id, seats, inviter || ethers.constants.AddressZero, {
        value:
          round.priceToken === ethers.constants.AddressZero ? payAmount : 0,
      })
      .then(tx => {
        tx.wait()
          .then(() => {
            rootProps.actions.reload();
            Message.success(
              locale['message']['seats buy complete'].replace(
                '#{seats}',
                seats,
              ),
            );
          })
          .catch(catchError)
          .finally(() => {
            setApprove(false);
            setInnerLoading(false);
          });
      })
      .catch(e => {
        setApprove(false);
        setInnerLoading(false);
        catchError(e);
      });
  };

  useEffect(() => {
    setApprove(false);
    setBuySeats(1);
  }, [user, round.id]);

  useEffect(() => {
    if (!round.seatPrice) {
      return;
    }

    const owned = user.account
      ? filter(seats, item => {
          return item[0].toLowerCase() === user.account.toLowerCase();
        })
      : [];
    const canBuy = round.seatLimitPerBuy.sub(owned.length);
    const remain = round.seatCapacity.sub(seats.length);
    const number =
      buySeats === 0 ? (remain.lt(canBuy) ? remain : canBuy) : buySeats;
    setRemainSeats(number);
    setPayAmount(round.seatPrice.mul(number));
  }, [round.id, seats, buySeats]);

  return round.status === LotteryStatus.Open ? (
    <div className={styles.payment}>
      <Space direction="vertical" size={30}>
        <Progress
          animation
          percent={
            (round.seatCapacity &&
              (100 * seats.length) / round.seatCapacity.toNumber()) ||
            0
          }
          color="var(--primary-color)"
          trailColor="var(--secondary-color)"
          strokeWidth={15}
          formatText={() => {
            return (
              <span className={styles.percent}>{`${seats.length}/${
                (round.seatCapacity && round.seatCapacity.toNumber()) || 0
              } ${locale['seats']}`}</span>
            );
          }}
        />
        <div className={styles.selector}>
          {SEATS.map(i => {
            return (
              <Button
                key={i}
                className={buySeats === i && styles.selected}
                onClick={() => setBuySeats(i)}
                disabled={approve || innerLoading}>
                {i
                  ? `${i} ${locale['seat']}`
                  : buySeats === i
                  ? `${remainSeats} ${locale['seat']}`
                  : locale['max seats']}
              </Button>
            );
          })}
        </div>
        <div className={styles.buy}>
          <Button
            type={approve ? 'warning' : 'primary'}
            onClick={approve ? onBuy : onApprove}
            loading={innerLoading}>
            {approve
              ? locale['confirm buy']
              : `${formatNumber(payAmount)} ${
                  network.tokenMapping[round.priceToken.toLowerCase()]
                } ${locale['buy']}`}
          </Button>
        </div>
      </Space>
    </div>
  ) : null;
}
