import { Button as ARButton, Empty as AREmpty } from '@arco-design/web-react';
import { IconLoading } from '@arco-design/web-react/icon';
import React from 'react';
import useLocale from '../../utils/useLocale';
import styles from './index.module.scss';

const classes = {
  primary: styles.primaryButton,
  warning: styles.warningButton,
  text: styles.textButton,
};
export const Button = props => {
  const { type, className, children, ...attributes } = props;
  return (
    <ARButton
      type={type}
      className={[classes[type] || styles.secondaryButton, className].join(' ')}
      {...attributes}>
      {children}
    </ARButton>
  );
};

export const Empty = props => {
  const { className, loading, icon, children, ...attributes } = props;
  const locale = useLocale();
  return (
    <AREmpty
      description={locale[loading ? 'loading' : 'empty']}
      icon={loading ? <IconLoading /> : null}
      className={[styles.empty, className].join(' ')}
      {...attributes}>
      {children}
    </AREmpty>
  );
};
