import {
  Badge,
  Divider,
  Dropdown,
  Menu,
  Message,
  Modal,
  Space,
} from '@arco-design/web-react';
import { IconClose, IconDown } from '@arco-design/web-react/icon';
import { ethers } from 'ethers';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { support } from '../../locale';
import { detectWallet, formatNumber, reduceAddress, toHex } from '../../utils';
import useCatchError from '../../utils/useCatchError';
import useContract from '../../utils/useContract';
import useLocale from '../../utils/useLocale';
import useNetwork from '../../utils/useNetwork';
import { Button } from '../Vendors';
import styles from './index.module.scss';

export default function Header(props) {
  const locale = useLocale();
  const network = useNetwork();
  const { rootProps } = props;
  const {
    lottery: { round },
    user,
  } = rootProps.state;
  const catchError = useCatchError();

  const { SwapRouteContract, LotteryContract } = useContract();
  const [price, setPrice] = useState('0.00000000');
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [totalReward, setTotalReward] = useState('0.00');
  const [totalAirdrop, setTotalAirdrop] = useState('0.00');
  const [totalBurn, setTotalBurn] = useState('0.00');

  useEffect(() => {
    SwapRouteContract.getAmountsOut(ethers.utils.parseEther('1'), [
      network.token.BEAT,
      network.token.WETH,
      network.token.BUSD,
    ])
      .then(result => {
        setPrice(formatNumber(result[2], 8));
      })
      .catch(() => {});
    LotteryContract.getTotalReward(network.token.BNB)
      .then(result => {
        setTotalReward(formatNumber(result, 2));
      })
      .catch(() => {});
    LotteryContract.totalAirdropInBeat()
      .then(result => {
        setTotalAirdrop(formatNumber(result, 2));
      })
      .catch(() => {});
    LotteryContract.totalBurnInBeat()
      .then(result => {
        setTotalBurn(formatNumber(result, 2));
      })
      .catch(() => {});
  }, [round.status]);

  const onBuy = () => {
    window.open(
      `${network.swap.url}?outputCurrency=${network.token.BEAT}`,
      '_blank',
    );
  };

  const onConnect = () => {
    setWalletModalVisible(true);
  };

  const onMenuChange = key => {
    if (key === 'logout') {
      rootProps.actions.logout();
      return;
    } else if (key === 'connect-metamask' || key === 'connect-binance') {
      connectWallet(key);
      return;
    }

    rootProps.actions.setLang({ locale: key });
  };

  const connectWallet = action => {
    let wallet, type;
    if (action === 'connect-metamask') {
      type = 'metamask';
    } else if (action === 'connect-binance') {
      type = 'bsc';
    }

    wallet = detectWallet(type);
    if (!wallet) {
      if (type === 'metamask') {
        Message.error(locale['message']['metamask not installed']);
      } else if (type === 'bsc') {
        Message.error(locale['message']['balance wallet not install']);
      }
      return;
    }

    const provider = new ethers.providers.Web3Provider(wallet, 'any');
    provider.on('network', ({ chainId }) => {
      if (+chainId !== network.chainId) {
        if (type === 'bsc') {
          wallet.switchNetwork(network.networkId).catch(catchError);
          return;
        }

        const _chainId = toHex(network.chainId);
        provider
          .send('wallet_switchEthereumChain', [
            {
              chainId: _chainId,
            },
          ])
          .catch(e => {
            if (e.code === 4902) {
              provider
                .send('wallet_addEthereumChain', [
                  {
                    chainId: _chainId,
                    chainName: network.chainName,
                    nativeCurrency: network.chainToken,
                    rpcUrls: [network.url],
                    blockExplorerUrls: [network.browser],
                  },
                ])
                .catch(catchError);
            } else {
              catchError(e);
            }
          });
        return;
      }

      provider
        .send('eth_requestAccounts')
        .then(accounts => {
          rootProps.actions.login({ account: accounts[0], wallet: type });
          setWalletModalVisible(false);
        })
        .catch(catchError);
    });
  };

  return (
    <div className={styles.header}>
      <div className={styles.top}>
        <div>
          <div>
            <img
              src={require('../../assets/logo.png')}
              alt="logo"
              className={styles.logo}
            />
          </div>
          <div>
            <Space>
              <Button type="secondary" onClick={onBuy}>
                {locale['buy beat']} <span className="highlight">${price}</span>
              </Button>
              {user.account ? (
                <Dropdown
                  droplist={
                    <Menu onClickMenuItem={onMenuChange}>
                      <Menu.Item key="logout">{locale['logout']}</Menu.Item>
                    </Menu>
                  }
                  position="bl">
                  <Button type="secondary">
                    <Space size="mini">
                      <Badge
                        status="success"
                        color="var(--primary-color)"
                        style={{
                          display: 'flex',
                        }}
                      />
                      <span>{reduceAddress(user.account, 2, 4, '...')}</span>
                      <IconDown />
                    </Space>
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  type="primary"
                  className={styles.connect}
                  onClick={onConnect}>
                  {locale['connect wallet']}
                </Button>
              )}

              <Dropdown
                droplist={
                  <Menu onClickMenuItem={onMenuChange}>
                    {map(support, (value, key) => {
                      return <Menu.Item key={key}>{value}</Menu.Item>;
                    })}
                  </Menu>
                }
                position="bl">
                <Button>
                  {locale['lang']}
                  <IconDown />
                </Button>
              </Dropdown>
            </Space>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div>
          <Space size={30}>
            <div>{locale['total rewards']}</div>
            <div>
              <div>{totalReward}</div>
              <img src={require('../../assets/bnb.png')} alt="BNB" />
            </div>
          </Space>
          <Divider type="vertical" />
          <Space size={30}>
            <div>{locale['total airdrop']}</div>
            <div>
              <div>{totalAirdrop}</div>
              <img src={require('../../assets/beat.png')} alt="BEAT" />
            </div>
          </Space>
          <Divider type="vertical" />
          <Space size={30}>
            <div>{locale['total burn']}</div>
            <div>
              <div>{totalBurn}</div>
              <img src={require('../../assets/beat.png')} alt="BEAT" />
            </div>
          </Space>
        </div>
      </div>
      <Modal
        title={locale['connect wallet']}
        visible={walletModalVisible}
        simple
        footer={null}
        alignCenter={false}
        onOk={() => setWalletModalVisible(false)}
        onCancel={() => setWalletModalVisible(false)}
        autoFocus={false}
        className={styles.walletModal}
        focusLock>
        <div className={styles.wrapper}>
          <Button
            className={styles.closeButton}
            onClick={() => setWalletModalVisible(false)}>
            <IconClose />
          </Button>
          <Menu onClickMenuItem={onMenuChange}>
            <Menu.Item key="connect-metamask">
              <img
                src={require('../../assets/metamask.png')}
                alt={locale['metamask wallet']}
              />
              <span>{locale['metamask wallet']}</span>
            </Menu.Item>
            <Menu.Item key="connect-binance">
              <img
                src={require('../../assets/binance.png')}
                alt={locale['binance wallet']}
              />
              <span>{locale['binance wallet']}</span>
            </Menu.Item>
          </Menu>
        </div>
      </Modal>
    </div>
  );
}
