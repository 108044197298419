import { forEach } from 'lodash';
import { useContext } from 'react';
import { GlobalContext } from '../context';

let tokenMapping;
function useNetwork() {
  const { network } = useContext(GlobalContext);
  if (!tokenMapping) {
    tokenMapping = {};
    forEach(network.token, (value, key) => {
      tokenMapping[value.toLowerCase()] = key;
    });
  }
  network.tokenMapping = tokenMapping;
  return network;
}

export default useNetwork;
